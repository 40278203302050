import type { AxiosResponse } from 'axios';
import config from 'config';
import type { Country } from '@fxtr/i18n';
import FetchJson from '$util/fetchJson';
import { internalAnalytics } from '$util/analytics/analyticsService/client';

const POSTCODE_URL = 'https://api.postcodes.io';

export const apiPostcodesIo = FetchJson({
  baseURL: POSTCODE_URL,
});

/**
 * Server side only
 */
export const googleGeocoderApi = FetchJson({
  baseURL: `https://maps.googleapis.com/maps/api/geocode`,
});
googleGeocoderApi.interceptors.request.use((reqConfig) => {
  const googleGeocodeServerApiKey = config.get<string>('googleGeocodeServer.apiKey');
  Object.assign(reqConfig.params, { key: googleGeocodeServerApiKey });
  return reqConfig;
});

export const getPostcode = (postCode: string): Promise<AxiosResponse> =>
  apiPostcodesIo.get(`/postcodes/${postCode}`);

export const autocomplete = (postCode: string): Promise<AxiosResponse> => {
  internalAnalytics.sendAnalyticsPostcodeTyped(postCode);
  return apiPostcodesIo.get(`/postcodes/${postCode}/autocomplete`);
};

interface GoogleGeocodeResponse {
  results: {
    geometry: {
      location: Record<'lat' | 'lng', number>;
    };
  }[];
}

export const fetchPostcodeLocation = (
  postcode: string,
  country: Country
): Promise<Record<'latitude' | 'longitude', number>> =>
  googleGeocoderApi
    .get<GoogleGeocodeResponse>('/json', {
      params: {
        components: `postal_code:${postcode}|country:${country}`,
      },
    })
    .then(
      ({
        data: {
          results: [result],
        },
      }) => {
        const location = result?.geometry.location;
        if (!location) {
          throw new TypeError(`No location found for postcode(${postcode}) on country(${country})`);
        }
        const { lat: latitude, lng: longitude } = location;
        return { latitude, longitude };
      }
    );
